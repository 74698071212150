import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ocean-protocol",
      "style": {
        "position": "relative"
      }
    }}>{`Ocean Protocol`}<a parentName="h1" {...{
        "href": "#ocean-protocol",
        "aria-label": "ocean protocol permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`In this discussion, we take a look at the Ocean Protocol - an open market for data. We begin by considering the "web3 sustainability loop" and then move into more speculative territory as we try to tease apart use cases, the various changes made to the token model over the years by the core team, whether anyone has actually earned money from data or other interactions in the marketplace, and what all this has to do with vote escrowed tokens.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://blog.oceanprotocol.com/the-web3-sustainability-loop-b2a4097a36e"
        }}>{`The web3 sustainability loop`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=sKbVieQ_foo"
        }}>{`The Web3 Data Economy: Ocean Protocol`}</a>{` by Trent McConaghy at Web3 Summit 2018`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://blog.oceanprotocol.com/introducing-veocean-c5f416c1f9a0"
        }}>{`Curating quality data with veOCEAN`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/oceanprotocol/papers/blob/master/tech-whitepaper.pdf"
        }}>{`The tech whitepaper`}</a></li>
    </ol>
    <h2 {...{
      "id": "application",
      "style": {
        "position": "relative"
      }
    }}>{`Application`}<a parentName="h2" {...{
        "href": "#application",
        "aria-label": "application permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Here is the `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/RSpbuhdtWdX4BslVHdm6Po/Tokens-Guild%3A-OCEAN-PROTOCOL?node-id=0%3A1"
      }}>{`figma board`}</a>{` we created during the session.`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/XhmWhMMXOGI" mdxType="Video" />
    <h2 {...{
      "id": "extra",
      "style": {
        "position": "relative"
      }
    }}>{`Extra`}<a parentName="h2" {...{
        "href": "#extra",
        "aria-label": "extra permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`While we don't cover it explicitly in this session, we recommend this `}<a parentName="p" {...{
        "href": "https://blog.oceanprotocol.com/nfts-ip-1-practical-connections-of-erc721-with-intellectual-property-dc216aaf005d?gi=78feb69142e4"
      }}>{`three part series on IP and NFTs`}</a>{` if you are interested in the topic.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      